<template>
  <div>
    <el-autocomplete
        v-model="value"
        v-bind="$attrs"
        v-on="$listeners"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入签约人"
        style="width: calc(96% - 1.2em)"
        @select="handleSelect"
        @change="changeValue"
        clearable></el-autocomplete>
    <!--    v-hasAuthority="'sign_signatory_setting'"-->
    <el-link v-if="showSet" @click="setPage"  v-hasAuthority="'sign_signatory_setting'">
      <svg-icon  :title="`来源部门:【${signatory}】,点击修改来源`" icon-class="qianyue" class="iconName"></svg-icon>
    </el-link>

    <el-dialog
        title="请选择签约数据来源部门"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleConfirm">
      <el-row>
        <el-col :span="1.5">
          部门中选择：
        </el-col>
        <el-col :span="1.5">
          <CompanySelect :allow-create="true" :multiple="true" :valueType="'name'" style="display: inline-block"
                         @handleSelect='handleChange'/>
        </el-col>

      </el-row>
      <br>
      <el-row>
        <el-col :span="1.5">
          自定义输入(使用 "," 作为分隔号)：
        </el-col>
        <el-col :span="1.5">
          <el-input v-model="inputValue" @change="inputInit" style="width: 200px" size="mini"></el-input>
        </el-col>

      </el-row>
      <br>
      <el-tag
          v-for="tag in signatory"
          :key="tag"
          :disable-transitions="false"
          closable
          style="margin-right: 10px"
          type="success"
          @close="handleClose(tag)">
        {{ tag }}
      </el-tag>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="saveDiag">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: "SignatoryQuery",
  data() {
    return {
      dataList: [],
      value: '',
      timeout: null,
      signatory: [],
      dialogVisible: false,
      inputValue: ''
    }
  },
  watch: {
    name(val) {
      this.value = val
    },

  },
  // computed: {
  //   value() {
  //     return this.name
  //   }
  // },
  props: {
    name: {
      type: String,
      default() {
        return ''
      }
    },
    showSet: {
      type: Boolean,
      default() {
        return false
      }
    }

  },
  methods: {
    inputInit() {
      if (this.inputValue != '') {
        let data=this.inputValue.split(',')
        this.signatory=[...this.signatory,..._.difference(data, this.signatory)]
      }
    },
    handleClose(tag) {
      this.signatory.splice(this.signatory.indexOf(tag), 1)
    },
    async saveDiag() {
      let id = await this.$api.saveSettingSiteInfo({ signatory: this.signatory.toString() })
      if (id) {
        this.$notify.success('保存成功！')
        this.dialogVisible = false

      }
    },
    handleConfirm() {
      this.dialogVisible = false
      this.initSettingSiteForm()
    },

    handleChange(selectArr) {
      this.signatory = selectArr
      return selectArr
    },

    async initSettingSiteForm() {
      // 获取站点分类列表
      let data = await this.$api.getSignatorySetting()
      this.signatory = data.value.split(',')
    },
    setPage() {
      this.dialogVisible = true
    },
    async initSignatoryOption() {
      let { list } = await this.$api.getSignInfoSignatoryList()
      this.dataList = []
      list.forEach((item) => {
        this.dataList.push({ value: `${item.name}`, label: item.name })
      })
    },
    querySearchAsync(queryString, cb) {
      var dataList = this.dataList
      var results = queryString ? dataList.filter(this.createStateFilter(queryString)) : dataList

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 500 * Math.random())
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      this.$emit('handleSelect', item)
    },
    changeValue() {
      this.$emit('changeValue', this.value)
    }
  },
  mounted() {
    this.dataList = this.initSignatoryOption();
    this.value = this.name
    this.initSettingSiteForm()
  }
}
</script>

<style scoped>
.iconName {
  display: inline-block;
  color: #ff3176;
  cursor: pointer;
}


</style>
