<template>
  <div>
    <el-row class='table-search'>
      <el-col :span='20'>
        <el-form :inline='true' v-model='searchCondition' label-position='right'>
          <el-form-item>
            <el-select v-model='searchCondition.sign_status' placeholder='签约状态' @change='search'
                       style='width: 120px!important;' clearable>
              <el-option :label='`在约红人`' :value='`Y`'>在约红人</el-option>
              <el-option :label='`解约红人`' :value='`N`'>解约红人</el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.date'
              start-placeholder='签约开始日期'
              end-placeholder='签约结束日期'
              type='monthrange'
              format='yyyy年MM月'
              value-format='yyyy-MM'
              @change='initList'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <SignatoryQuery v-model='searchCondition.signatory' :name='searchCondition.signatory'
                            :showSet='false' @handleSelect='handleSelect'
                            @changeValue='changeSignatory' />
          </el-form-item>
          <el-form-item>
            <el-input v-model='searchCondition.nickname' placeholder='选择红人昵称' :clearable='true'
                      @change='search'></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='search'><i class='el-icon-search'></i>搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span='4' style='text-align: right'>
        <export-link ref='refExportLink' :can-export="userPermissions.indexOf('sign_info_export') !== -1"
                     @export='exportData' />
      </el-col>
    </el-row>
    <!--    签约信息列表-->
    <el-row>
      <el-col :span='24'>
        <!--   table列表     -->
        <div class='default-table'>
          <ApeTable ref='apeTable'
                    :data='dataList'
                    :columns='columns'
                    :loading='loadingStatus'
                    :pagingData='pagingData'
                    @switchPaging='switchPaging'
                    @changeTableSort='changeTableSort'
                    highlight-current-row
                    border>
            <el-table-column width='80' prop='index' label='序号' slot='first-column' align='center'>
              <template slot-scope='{$index}'>
                {{ offset + $index + 1 }}
              </template>
            </el-table-column>
            <el-table-column align='center' min-width='110' prop='nickname' label='红人昵称' slot='second-column'>
              <template slot-scope='scope'>
                <artist-nickname :info='scope.row' />
              </template>
            </el-table-column>

            <!--            <el-table-column min-width="100" prop="signatory" label="签约人" slot="second-column"></el-table-column>-->
            <!--            <el-table-column min-width="100" prop="nature_alias" label="合约性质" slot="second-column"></el-table-column>-->
            <el-table-column min-width='100' prop='group_id' label='分配结果' align='center' sortable='custom'>
              <template slot-scope='{row}'>{{ row.group_name }}</template>
            </el-table-column>
            <el-table-column min-width='100' prop='fired_at' label='解约日期' align='center' sortable='custom'>
              <template slot-scope='{row}'>
                {{ row.fired_at }} <el-link @click='changeFiredTime(row)' v-if='row.fired_at&&row.fired_at!=="-"' style='float: right;' icon='el-icon-edit-outline'></el-link>
              </template>
            </el-table-column>
            <el-table-column min-width='100' prop='fired_type' label='解约类型' align='center'>
              <template slot-scope='{row}'>{{ row.fired_type_alias || '' }}</template>
            </el-table-column>
            <el-table-column width='110' align='center' prop='sign_status' label='签约状态' sortable='custom'>
              <template slot-scope='scope'>
                <el-link :type="`${scope.row.sign_status === 'Y' ? 'primary' : 'info'}`">
                  {{ scope.row.sign_status === 'Y' ? '签约' : '解约' }}
                </el-link>
              </template>
            </el-table-column>
            <!--            <el-table-column min-width="80" prop="account_source" label="账号来源" align="center" :formatter="accountSourceFormat"></el-table-column>-->
            <el-table-column
              v-if="buttonType=='icon'"
              width='140'
              label='操作' align='center' fixed='right'>
              <template slot-scope='scope'>
                <el-tooltip effect='dark' content='编辑' placement='top-start'>
                  <el-link type='primary' size='mini' icon='el-icon-tickets'
                           @click='detailPage(scope.row.id)'>详情
                  </el-link>
                </el-tooltip>
                &nbsp;
                <el-dropdown>
                <span class='el-dropdown-link'>
                  更多<i class='el-icon-arrow-down el-icon--right'></i>
                </span>
                  <el-dropdown-menu slot='dropdown'>
                    <el-dropdown-item>
                      <el-link type='primary' size='mini'
                               @click='jumpToPage("transfer_group")' v-if='userPermissions.indexOf("sign_transfer_group")>-1'>
                        <svg-icon :fill="'currentColor'" :class='`iconfont`' style='display: inline-block'
                                                                                icon-class="zhuanzu"></svg-icon>

                        转组
                      </el-link>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-link type='primary' size='mini'
                               @click='jumpToPage("sign_fired")' v-if='userPermissions.indexOf("sign_fired_page")>-1'>
                        <svg-icon :fill="'currentColor'" :class='`iconfont`' style='display: inline-block'
                                  icon-class="jieyue"></svg-icon>
                        解约
                      </el-link>
                      </el-dropdown-item>
                    <el-dropdown-item divided>
                      <el-link type='primary' size='mini'
                               @click='handleDelete(scope.row)' v-if='userPermissions.indexOf("sign_info_delete")>-1'>
                        <svg-icon :fill="'currentColor'" :class='`iconfont`' style='display: inline-block'
                                  icon-class="del"></svg-icon>删除
                      </el-link>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <el-table-column
              v-if="buttonType=='text'"
              width='80'
              label='操作' fixed='left'>
              <template slot-scope='scope'>
              <span>
                <el-button size='mini' @click='detailPage(scope.row.id)' v-if='userPermissions.indexOf("sign_fired_page")>-1'>编辑</el-button>
              </span>
              </template>
            </el-table-column>
          </ApeTable>
        </div>
      </el-col>
    </el-row>

    <!--    备注-->
    <el-row>
      <el-col :span='24'>
        <Tips :data='tipsList' />
      </el-col>
    </el-row>

    <change-fired-time ref='changeFiredTime' @handleSubmit='initList'/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import Tips from '@/components/Tips'
import SignatoryQuery from '@/pages/sign_info/components/SignatoryQuery'
import ExportLink from '@/components/export/ExportLink'
import ChangeFiredTime from '@/pages/sign_info/ChangeFiredTime'

export default {
  name: 'SignList',
  components: {
    ChangeFiredTime,
    PageHeaderLayout, ApeTable, Tips, SignatoryQuery, ExportLink
  },
  computed: {
    ...mapGetters(['isCollapse', 'userInfo', 'menuList', 'routePath', 'userPermissions', 'notifyTotal', 'buttonType'])
  },
  data() {
    return {
      orderSort: { sign_date: 'desc', id: 'desc' },//默认排序规则
      tipsList: [
        '身份证只有签约部和总监及以上同时有权限',
        '分配结果为分配到某个组',
        '博主分成条件查看权限为总监及以上'
      ],
      accountSourceOptions: [
        { label: '组内提供微信', val: 'pwg' },
        { label: '自己寻找', val: 'fys' }
      ],
      searchCondition: { signatory: null, year: null, month: null, sign_status: null, date: null },
      monthOptions: [],
      signatoryOptions: [],
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      offset: 0,
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 20
        // offset: 0
      },
      columns: [
        // {
        //   title: '红人昵称',
        //   value: 'nickname',
        //   width: 120,
        //   header_align: 'center',
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '年份',
        //   value: 'year_alias',
        //   width: 100,
        //   header_align: 'center',
        //   align: 'center'
        // },
        // {
        //   title: '月份',
        //   value: 'month_alias',
        //   width: 100,
        //   header_align: 'center',
        //   align: 'center'
        // },
        {
          title: '签约日期',
          value: 'sign_date',
          width: 100,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '签约人',
          value: 'signatory',
          width: 100,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        // {
        //   title: '账号名',
        //   value: 'account_name',
        //   width: 100,
        //   header_align: 'center',
        //   align: 'center'
        // },
        // {
        //   title: '博主真实姓名',
        //   value: 'real_name',
        //   width: 110,
        //   header_align: 'center',
        //   align: 'center'
        // },
        // {
        //   title: '身份证号',
        //   value: 'id_card',
        //   width: 110,
        //   header_align: 'center',
        //   align: 'center'
        // },
        {
          title: '签约后主平台',
          value: 'platform_name_aft',
          width: 110,
          header_align: 'center',
          align: 'center'
          // sortable: "custom"
        },
        // {
        //   title: '签约前平台',
        //   value: 'platform_name_bef',
        //   width: 110,
        //   header_align: 'center',
        //   align: 'center'
        // },
        // {
        //   title: '签约前粉丝量',
        //   value: 'fans_total_bef',
        //   width: 110,
        //   header_align: 'center',
        //   align: 'center'
        // },
        {
          title: '合约性质',
          value: 'nature_alias',
          width: 100,
          header_align: 'center',
          align: 'center'
          // sortable: "custom"
        }
        // {
        //   title: '合约年限',
        //   value: 'term',
        //   width: 100,
        //   header_align: 'center',
        //   align: 'center'
        // },
        // {
        //   title: '博主分成(%)',
        //   value: 'blogger_ratio',
        //   width: 110,
        //   header_align: 'center',
        //   align: 'center'
        // },
        // {
        //   title: '分配结果',
        //   value: 'group_name',
        //   width: 100,
        //   header_align: 'center',
        //   align: 'center',
        //   sortable: "custom"
        // },
        // {
        //   title: '备注',
        //   value: 'remark',
        //   width: 200,
        //   header_align: 'center',
        //   align: 'left'
        // },
        // {
        //   title: '解约日期',
        //   value: 'fired_at',
        //   width: 100,
        //   header_align: 'center',
        //   align: 'center',
        //   sortable: "custom"
        // },
      ]
    }
  },
  created() {
    this.initOptions()

  },
  mounted() {
    this.initList()
  },
  methods: {
    // 投票状态字典翻译
    // eslint-disable-next-line no-unused-vars
    accountSourceFormat(row, column) {
      return this.selectDictLabel(this.accountSourceOptions, row.account_source)
    },
    // 回显数据字典
    selectDictLabel(datas, value) {
      var actions = []
      Object.keys(datas).map((key) => {
        if (datas[key].val === value) {
          actions.push(datas[key].label)
          return false
        }
      })
      return actions.join('')
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    //初始化月份下拉框
    initOptions() {
      this.monthOptions = []
      for (let i = 1; i <= 12; i++) {
        this.monthOptions.push({ value: i, label: i + '月份' })
      }
      this.initSignatoryOption()
    },
    async initSignatoryOption() {
      let { list } = await this.$api.getSignInfoSignatoryList()
      this.signatoryOptions = []
      list.forEach((item) => {
        this.signatoryOptions.push({ value: item.userid, label: item.name })
      })
    },
    handleMenuSelect(routePath) {
      if (routePath)
        this.$router.push(routePath)
    },
    search() {
      this.$refs['apeTable'].handleCurrentChange(1)
    },
    // 初始化列表
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getSignInfoList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.sign_status) {
        condition.sign_status = this.searchCondition.sign_status
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.month) {
        condition.month = this.searchCondition.month
      }

      if (this.searchCondition.signatory) {
        condition.signatory = this.searchCondition.signatory
      }
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }

      if (this.searchCondition.date && this.searchCondition.date.length === 2) {
        condition.start_date = this.searchCondition.date[0]
        condition.end_date = this.searchCondition.date[1]
      }

      return condition
    },
    // 响应编辑按钮
    async editButton(id) {
      this.$router.push('/sign/' + id + '/edit')
    },
    async detailPage(id) {
      this.$router.push('/sign/' + id + '/detail')
    },
    //导出表格
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportSignInfoList(searchCondition)
        let name = `签约信息-${searchCondition.year ? searchCondition.year + '年' : ''}${searchCondition.month ? searchCondition.month + '月' : ''}`

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    //切换签约人
    handleSelect(item) {
      this.searchCondition.signatory = item.value
      this.search()
    },
    async changeSignatory(val) {
      this.searchCondition.signatory = val
      this.search()
    },
    jumpToPage(pageName, params) {
      this.$router.push({
        name: pageName,
        params: params
      })
      // const { href } = this.$router.resolve({
      //   name: pageName,
      //   params: params
      // })
      // window.open(href, '_blank')
    },
    handleDelete(row) {
      this.$confirm(`确认删除红人【${row.nickname}】的签约信息（仅删除签约记录，不删除红人数据）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteSignInfo(row.id)
        if (info) {
          this.$notify.success('删除成功')
          await this.initList()
        } else {
          this.$notify.error('未知错误')
        }
      }).catch(() => {
        this.$notify.info('已取消')
      })
    },
    //
    changeFiredTime(row){
      this.$refs['changeFiredTime'].show(row)

    }

  }
}
</script>

<style scoped>

</style>
