<template>
  <div>
    <el-dialog
      title='修改红人解约时间'
      :visible.sync='dialogVisible'
      width='500px'
    >
      <div>
        <el-form ref='form' :model='form' label-width='120px' :rules='rules'>
          <el-form-item label='红人昵称：' prop='nickname'>
            <span>{{ form.nickname }}</span>
            <!--            <el-input v-model='form.nickname' placeholder='红人昵称' clearable disabled/>-->
          </el-form-item>
          <el-form-item label='解约时间：' prop='fired_at'>
            <el-input v-model='form.fired_at' placeholder='解约时间(格式：yyyy/MM/dd，例如 2024/07/09)' clearable />
            <el-date-picker
              v-model='form.fired_at'
              type='date'
              clearable
              format='yyyy/MM/dd'
              value-format='yyyy/MM/dd'
              placeholder='选择解约时间'>
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogVisible = false'>取 消</el-button>
        <el-button type='primary' :loading='is_loading' :disabled='is_loading' @click='handleSubmit'>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ChangeFiredTime',
  data() {
    return {
      dialogVisible: false,
      is_loading: false,
      form: {
        id: null,
        nickname: null,
        fired_at:null
      },
      rules: {
        nickname: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        fired_at: [
          { required: true, message: '请输入解约时间', trigger: 'blur' }
        ]
      }

    }
  },
  methods: {
    show(row) {
      this.form = { id: row.id, nickname: row.nickname, fired_at: row.fired_at }
      this.dialogVisible = true
      this.is_loading = false
    },
    handleSubmit() {

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.is_loading = true
          const data = await this.$api.editSignFiredTime({ id: this.form.id, fired_at: this.form.fired_at })
          this.$emit('handleSubmit', data)
          this.dialogVisible = false
          this.is_loading = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
